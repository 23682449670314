<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item open ref="debitCollapse">
        <div slot="header">
          <h3 class="text-left card-title font-normal">Debit account <span class="text-2xl mid-blue">*</span></h3>
        </div>
        <p class="mb-8">Select a bank account for {{ appName }} to debit your repayments from</p>
        <vs-row>
          <vs-col vs-w="6">
            <div class="input-grp">
              <vs-select v-model="debit.bankId" name="selectedDebitAccount" id="selectedDebitAccount" class="w-full selectExample" :placeholder="'Select account'" v-validate="'required'" data-vv-as="debit account">
                <vs-select-item :key="index" :value="item.bankId" :text="item.displayName ? item.displayName : item.accountName" v-for="(item, index) in merchantBanks" />
              </vs-select>
            </div>
            <span class="text-danger text-sm" v-show="errors.has('selectedDebitAccount')">{{ errors.first("selectedDebitAccount") }}</span>
            <div class="flex">
              <vs-switch v-model="debit.allowOverride" name="allowOverride"></vs-switch>
              <p class="ml-5">Request sender can change debit account (can only select from <b>your verified accounts</b>)</p>
            </div>
          </vs-col>
        </vs-row>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
  export default {
    name: "Debit",

    props: ["appName", "debit", "isSubmitted", "merchantBanks"],

    watch: {
      async isSubmitted(val) {
        if (val) {
          await this.startValidating();
        }
      }
    },

    methods: {
      async startValidating() {
        const valid = await this.$validator.validate();

        if (!valid) {
          this.$refs.debitCollapse.maxHeight = "none !important";
        }
      },
    }
  }
</script>

