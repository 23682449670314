<template>
  <img v-if="isBase64" :src="document" alt="img" class="responsive" :class="customClass" />
  <img v-else :src="temporaryLinkUri" alt="img" class="responsive" :class="customClass" />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "S3ImageGenerator",
  props: ["isBase64", "document", "customClass"],
  data() {
    return {
      temporaryLinkUri: ""
    }
  },
  mounted() {
    this.generateTemporaryLink().then(s3Data => {
      if (s3Data && s3Data.hasOwnProperty("status") && s3Data.status === 200) {
        this.temporaryLinkUri = s3Data.data.data.url;
      }
    });
  },
  methods: {
    ...mapActions("s3ServiceModule", ["fetchFile"]),

    async generateTemporaryLink() {
      if (!this.isBase64) {
        return await this.fetchFile(this.document);
      }
    }
  }
}
</script>

<style scoped>
.profile-page {
  max-width: 225px;
  max-height: 225px;
  object-fit: cover;
}
.profile-image-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 25px;
}
.profile-image-circle-nav {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.card-image-sm {
  width: 40px;
  height: 24px;
  margin-right: 12px;
  object-fit: contain;
}
.card-library-image {
  max-width: 100%;
  object-fit: contain;
}
.payment-card {
  width: 50px;
}
.profile-image-circle-60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 25px;
}

.profile-page-150 {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.width-100 {
  width: 100px;
}

.table-image {
  width: 50px;
}
</style>

