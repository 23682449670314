<template>
  <vs-row>
    <label class="w-full text-base font-light" v-if="templateConfigType === 'pay-now'">Set a default message to be displayed to customers when they view the payment request</label>
    <label class="w-full text-base font-light" v-else-if="templateConfigType === 'pay-later'">Default message to display to customers when viewing the payment request or hosted payment page</label>
    <div class="card-body">
      <vs-row class="mt-5">
        <vs-col vs-lg="12">
          <div class="input-grp">
          <vs-textarea
            counter="250"
            maxlength="250"
            row="3"
            name="bodyText"
            v-model="customTextBlockData.body"
            id="customer-message"
            @input="auto_grow('customer-message')"
          ></vs-textarea>
          </div>
        </vs-col>
      </vs-row>
      <div class="mt-8 mb-5 flex flex-row">
        <div>
          <vs-checkbox v-model="customTextBlockData.allowOverride"></vs-checkbox>
        </div>
        <div>
          <span class="info text-black text-base font-normal">Allow request sender to change this default message when sending a request</span>
        </div>
      </div>
    </div>
  </vs-row>
</template>

<script>
import { Validator } from "vee-validate";
const dictionary = {
  en: {
    attributes: {
      bodyText: "body",
    },
  },
};
Validator.localize(dictionary);
export default {
  props: ["customTextBlockData", "templateConfigType"],
  watch: {
    "customTextBlockData.body": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("checkDirty", true);
      }
    },
    "customTextBlockData.allowOverride": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("checkDirty", true);
      }
    },
  },
  methods: {
    auto_grow(elem) {
      var element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
    }
  },
  updated() {
    this.auto_grow('customer-message');
  },
};
</script>