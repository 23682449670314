<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item open ref="disbursementCollapse">
        <div slot="header">
          <h3 class="text-left card-title font-normal">Disbursement <span class="text-2xl mid-blue" v-if="expandCard">*</span></h3>
        </div>
        <p class="mb-8" v-if="templateConfigType == 'pay-later'">Select a bank account to disburse to (i.e. receive funds into) when payments using this template are processed</p>
        <p class="mb-8" v-else>Select a default account to disburse to, when requests using this template are approved</p>

        <vs-row>
          <vs-col vs-w="6">
            <div v-if="isExternalDisbursement && customerEnabled">
              <vs-radio v-model="disbursement.type" vs-value="customer" vs-name="disbursementType" @change="handleAccountChange" :disabled="disableOptions">Customer will enter an account manually</vs-radio><br>
            </div>
            <div v-if="isExternalDisbursement && introducerEnabled">
              <vs-radio v-model="disbursement.type" vs-value="introducer" vs-name="disbursementType" @change="handleAccountChange" :disabled="disableOptions">Set by integrated partner</vs-radio><br>
            </div>
            <div v-if="isExternalDisbursement">
              <vs-radio v-model="disbursement.type" vs-value="requestUser" vs-name="disbursementType" @change="handleAccountChange" :disabled="disableOptions">User will enter when creating request</vs-radio><br>
            </div>
            <div v-if="isExternalDisbursement && hasExternalAccount">
              <vs-radio v-model="disbursement.type" vs-value="externalAccount" vs-name="disbursementType" @change="handleAccountChange" :disabled="disableOptions">Select a saved external account</vs-radio><br>
            </div>
            <div>
              <vs-radio v-model="disbursement.type" vs-value="merchantAccount" vs-name="disbursementType" @change="handleAccountChange" :disabled="disableOptions">Select one of your bank accounts</vs-radio><br>
            </div>
            <span class="text-danger" v-show="errors.has('disbursementType')">{{ errors.first("disbursementType") }}</span>
          </vs-col>
          <vs-col vs-w="6">
            <div class="input-grp" v-if="['externalAccount', 'merchantAccount'].includes(disbursement.type)">
              <vs-select v-model="selectedAccount" name="selectedAccount" id="selectedAccount" class="w-full selectExample" :placeholder="'Select account'" :disabled="selectAccountDisabled" @change="changeSelectedAccount">
                <vs-select-item :key="index" :value="item.bankId" :text="item.displayName ? item.displayName : item.accountName" v-for="(item, index) in bankList" />
              </vs-select>
            </div>
            <span class="text-danger text-sm" v-show="errors.has('selectedAccount')">{{ errors.first("selectedAccount") }}</span>
          </vs-col>
          <vs-col vs-w="12">
            <div class="mt-8">
              <p class="mb-8">Additional controls</p>
              <div class="flex" v-if="isExternalDisbursement">
                <vs-switch v-model="disbursement.allowAnyBank" name="allowAnyBank" :disabled="disableToggles" @change="handleToggleChange"></vs-switch>
                <p class="ml-5">Request sender can disburse to <b>ANY</b> bank account <br>(can enter any BSB/account number)</p>
              </div>
              <div class="flex mt-2" v-if="isExternalDisbursement && hasExternalAccount">
                <vs-switch v-model="disbursement.allowExternalAccount" name="allowExternalAccount" :disabled="disableToggles" @change="handleToggleChange"></vs-switch>
                <p class="ml-5">Request sender can disburse to any <b>saved external account</b> <br>(can only select from your saved external accounts)</p>
              </div>
              <div class="flex mt-2">
                <vs-switch v-model="disbursement.allowMerchantAccount" name="allowMerchantAccount" :disabled="disableToggles" @change="handleToggleChange"></vs-switch>
                <p class="ml-5">Request sender can disburse to any of your bank accounts <br>(can only select from <b>your verified accounts</b>)</p>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('disbursementToggles')">{{ errors.first("disbursementToggles") }}</span>
            </div>
          </vs-col>
        </vs-row>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
  export default {
    name: "Disbursement",

    data() {
      return {
        bankList: [],
        customerEnabled: false,
        disableOptions: false,
        disableToggles: false,
        expandCard: true,
        introducerEnabled: false,
        isExternalDisbursement: false,
        merchantEnabled: false,
        selectedAccount: null,
      };
    },

    props: ["disbursement", "externalAccounts", "isSubmitted", "linkedAccount", "merchantBanks", "selectedPlan", "templateConfigType"],

    watch: {
      async isSubmitted() {
        await this.validateSettings();
      },
      "selectedPlan.customPlanId"(val) {
        if (val) {
          this.getProductSettings(true);
        }
      },
      expandCard(val) {
        if (val) {
          this.$refs.disbursementCollapse.maxHeight = "none";
        } else {
          this.$refs.disbursementCollapse.maxHeight = "0px";
        }
      },
      "disbursement.type"(val) {
        if (val) {
          this.$emit("changeRemittanceSettings", val);
        }
      }
    },

    methods: {
      async handleAccountChange(event, initial = false) {
        this.selectedAccount = null;
        this.bankList = [];
        this.disableToggles = this.isExternalDisbursement ? !this.merchantEnabled : false;

        if (initial && this.selectedPlan) {
          this.getDisbursementOptions();
        }

        if (this.hasExternalAccount && this.disbursement.type == "externalAccount") {
          this.bankList = [ ...this.externalAccounts ];
        } else if (this.hasMerchantAccount && this.disbursement.type == "merchantAccount") {
          this.bankList = [ ...this.merchantBanks ];
        } else if (this.disbursement.type == "customer") {
          this.disableToggles = true;
          this.disbursement.allowAnyBank = false;
          this.disbursement.allowExternalAccount = false;
          this.disbursement.allowMerchantAccount = false;
        }

        if (initial && this.linkedAccount) {
          const templateAccount = this.bankList.find((item) => item.bankId == this.linkedAccount);
          this.selectedAccount = templateAccount && templateAccount.bankId ? templateAccount.bankId : null;
        } else if (["externalAccount", "merchantAccount"].includes(this.disbursement.type) && this.merchantEnabled && this.bankList.length && (this.bankList.length == 1)) {
          this.selectedAccount = this.bankList[0].bankId;
        }

        if (Object.keys(this.disbursement.type).length && this.errors.has("disbursementType")) {
          this.errors.remove("disbursementType");
        }

        if (this.errors.has("selectedAccount") && (!["merchantAccount", "externalAccount"].includes(this.disbursement.type) || this.selectedAccount)) {
          this.errors.remove("selectedAccount");
        }

        if (this.errors.has("disbursementToggles")) {
          this.errors.remove("disbursementToggles");
        }

        this.$emit("changeLinkedAccount", this.selectedAccount ? this.selectedAccount : {});
      },

      async getProductSettings(productChange = false) {
        this.selectedAccount = null;
        this.expandCard = true;
        await this.disableSettings();
        await this.getDisbursementOptions();

        if ((this.templateConfigType == "direct") && this.merchantEnabled) {
          this.disbursement.type = "";
        } else if (this.customerEnabled) {
          this.disbursement.type = "customer";
          this.expandCard = this.merchantEnabled;
        } else if (!this.customerEnabled && !this.merchantEnabled && this.introducerEnabled) {
          this.disbursement.type = "introducer";
          this.expandCard = false;
        } else if ((!this.customerEnabled && this.merchantEnabled) || (!this.customerEnabled && !this.merchantEnabled && !this.introducerEnabled)) {
          this.disbursement.type = "merchantAccount";
        }

        if (this.disableToggles) {
          this.disbursement.allowAnyBank = false;
          this.disbursement.allowExternalAccount = false;
          this.disbursement.allowMerchantAccount = false;
        }

        if (productChange) {
          this.handleAccountChange();
        }
      },

      async disableSettings() {
        this.disbursement.allowAnyBank = false;
        this.disbursement.allowMerchantAccount = false;
        this.disbursement.allowExternalAccount = false;
        this.disbursement.type = "";
        this.merchantEnabled = false;
        this.customerEnabled = false;
        this.introducerEnabled = false;
      },

      validateSettings() {
        let valid = true;

        if (!this.hasExternalAccount) {
          this.disbursement.allowExternalAccount = false;
        }

        if (!this.disbursement.type || !Object.keys(this.disbursement.type).length) {
          valid = false;
          this.errors.add({
            field: "disbursementType",
            msg: "Please select at least one disbursement option to proceed",
          });
        } else if (["merchantAccount", "externalAccount"].includes(this.disbursement.type) && !this.selectedAccount) {
          valid = false;
          this.errors.add({
            field: "selectedAccount",
            msg: "Please select at least one account to proceed",
          });
        } else if ((this.disbursement.type == "requestUser") && (!this.disbursement.allowAnyBank && !this.disbursement.allowExternalAccount && !this.disbursement.allowMerchantAccount)) {
          valid = false;
          this.errors.add({
            field: "disbursementToggles",
            msg: "Please enable at least one option",
          });
        }

        if (!valid) {
          this.$refs.disbursementCollapse.maxHeight = "none !important";
        } else {
          this.errors.remove("disbursementType");
          this.errors.remove("selectedAccount");
          this.errors.remove("disbursementToggles");
        }
      },

      changeSelectedAccount() {
        let selectedBank = {};

        if (this.bankList.length > 0) {
          const objIndex = this.bankList.findIndex((obj) => obj.bankId === this.selectedAccount);
          selectedBank = this.bankList[objIndex];
        }

        if (this.errors.has("selectedAccount") && this.selectedAccount) {
          this.errors.remove("selectedAccount");
        }

        this.$emit("changeLinkedAccount", selectedBank);
      },

      handleToggleChange() {
        if (this.errors.has("disbursementToggles")) {
          this.errors.remove("disbursementToggles");
        }
      },

      async getDisbursementOptions() {
        this.isExternalDisbursement = this.selectedPlan && this.selectedPlan.disburseTo && (this.selectedPlan.disburseTo == "external") ? true : false;

        if (this.isExternalDisbursement) {
          this.customerEnabled = this.selectedPlan.editDisbursement["customer"];
          this.merchantEnabled = this.selectedPlan.editDisbursement["merchant"];
          this.introducerEnabled = !this.merchantEnabled && !this.customerEnabled && this.selectedPlan.editDisbursement["introducer"];
          this.disableToggles = !this.merchantEnabled || (this.disbursement.type == "customer");
          this.disableOptions = !this.merchantEnabled;
        } else {
          this.disbursement.type = "merchantAccount";
          this.disableOptions = false;
          this.disableToggles = false;
        }
      }
    },

    async mounted() {
      if (!this.$route.params.id) {
        await this.getProductSettings();
      }

      await this.handleAccountChange(null, this.$route.params.id);
    },

    computed: {
      hasExternalAccount() {
        return this.externalAccounts && this.externalAccounts.length ? true : false;
      },

      hasMerchantAccount() {
        return this.merchantBanks && this.merchantBanks.length ? true : false;
      },

      selectAccountDisabled() {
        return this.isExternalDisbursement && !this.merchantEnabled;
      }
    }
  }
</script>

