<template>
  <vs-row>
    <vs-col v-if="paymentDataDefault.payLater && requestOptionArray.includes('pay-later')">
      <label class="w-full text-base font-light">Select a finance option</label>
      <div class="input-grp">
        <vs-select autocomplete class="selectExample" v-model="defaultData.payLaterPlan" @change="changeProductConfig">
          <vs-select-item :key="index" :value="item.customPlanId" :text="item.productName" v-for="(item, index) in productOptions" />
        </vs-select>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-align="center" vs-w="12">
      <span class="text-danger text-sm" v-show="errors.has('paylaterPlans')">{{ errors.first("paylaterPlans") }}</span>
    </vs-col>
  </vs-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { Validator } from "vee-validate";
import checkbox from "../../../../components/checkbox.vue";
import radiobox from "../../../../components/radiobox.vue";

const dictionary = {
  en: {
    attributes: {
      paylaterPlans: "pay later plan",
    },
  },
};
Validator.localize(dictionary);

export default {
  components: {
    checkbox,
    DatePicker,
    radiobox,
  },

  props: [
    "editFlag",
    "isSubmitted",
    "paymentDataDefault",
    "paymentOptionData",
    "paymentOptionFrequency",
    "planOptions",
    "productConfigType",
    "templateConfigType"
  ],

  data() {
    return {
      defaultData: {},
      partnerId: JSON.parse(localStorage.getItem("user")).userType == "admin" ? JSON.parse(localStorage.getItem("user"))._id : JSON.parse(localStorage.getItem("user")).partnerId,
      paymentHeader: "",
      productOptions: [],
    };
  },

  watch: {
    async isSubmitted() {
      this.startValidating();
    },

    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  },

  methods: {
    async startValidating() {
      const valid = await this.$validator.validate();

      if (this.paymentOptionData.requestType == "") {
        this.errors.add({
          field: "requestType",
          msg: "Please select one option",
        });
      }

      if (this.paymentOptionData.requestOptions.includes("pay-later") && !this.paymentOptionData.payLaterPlan.length) {
        this.errors.add({
          field: "paylaterPlans",
          msg: "Please select one option",
        });
      }
    },

    submittedFalse() {
      this.isSubmitted = false;
    },

    selectOption(val) {
      this.defaultData.requestType = "one-off";
      this.defaultData.requestOptions = ["direct", "pay-later"].includes(val) ? ["pay-later"] : ["pay-now"];
      this.paymentHeader = (val == "pay-later") ? "Pay later options" : "Direct request options";
    },

    changeProductConfig() {
      const selectedData = this.productOptions.find((element) => element.customPlanId == this.defaultData.payLaterPlan);

      if (selectedData) {
        this.paymentHeader = (selectedData.productConfigType == "THREE_PARTY") ? "Pay later options" : "Direct request options";
        this.$emit("updateProductConfig", selectedData);

        if (selectedData.productConfigType == "TWO_PARTY" && this.defaultData.requestOptions.includes("pay-now")) {
          this.defaultData.requestOptions = ["pay-later"];
        }

        if (this.errors.has("paylaterPlans")) {
          this.errors.remove("paylaterPlans");
        }
      }

    },
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },

    requestTypeArray() {
      const requestType = this.$lodashHelper.checkObjectKey(this.paymentDataDefault, "requestTypes", []);
      if (Array.isArray(requestType)) {
        return requestType;
      }
      return [];
    },

    requestOptionArray() {
      const requestOption = this.$lodashHelper.checkObjectKey(this.defaultData, "requestOptions", []);

      if (Array.isArray(requestOption)) {
        return requestOption;
      }

      return [];
    }
  },

  async mounted() {
    this.productOptions = [...this.planOptions];
    this.defaultData = this.paymentOptionData;

    if (this.paymentDataDefault.requestOptions && this.paymentDataDefault.requestOptions[0] == "pay-later" && this.paymentDataDefault.requestOptions.length == 1) {
      this.defaultData.requestOptions = ["pay-later"];
      this.defaultData.requestType = "one-off";
    }

    if (this.editFlag) {
      this.changeProductConfig();
    } else {
      this.selectOption(this.templateConfigType);
    }
  },
};
</script>

