<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="senderCollapse">
        <div slot="header">
          <h3 class="text-left card-title font-normal">Redirect on success</h3>
        </div>
        <vs-row class="success-redirect">
          <vs-row>
            <vs-col vs-w="12" class="success-redirect-text">
              <p>Set up where you would like to redirect customers after they complete their payment</p>
            </vs-col>
          </vs-row>

          <vs-row class="my-8">
            <vs-col vs-w="4" class="flex">
              <vs-switch v-model="redirectData.success.enableRedirect" />
              <p class="ml-8">{{ redirectData.success.enableRedirect ? "Enabled" : "Disabled" }}</p>
            </vs-col>
          </vs-row>

          <vs-row class="mt-8 input-space" v-if="redirectData.success.enableRedirect">
            <div class="input-grp mb-6 md:mb-8">
              <span class="text-danger text-sm" v-if="errors.has('SuccessHeading')">{{ errors.first("SuccessHeading") }}</span>
              <vs-input class="w-full" v-validate="'required|min:3'" size="large" v-model="redirectData.success.heading" name="SuccessHeading" id="SuccessHeading" />
              <label for="SuccessHeading" class=" w-full font-light">Card heading</label>
            </div>
            <div class="input-grp mb-6 md:mb-8" >
              <span class="text-danger text-sm" v-if="errors.has('SuccessMessage')">{{ errors.first("SuccessMessage") }}</span>
              <vs-textarea class="w-full" v-validate="'required|min:3|max:250'" size="large" v-model="redirectData.success.message" counter="250" maxlength="250" name="SuccessMessage"
                id="SuccessMessage" @input="auto_grow('SuccessMessage')"/>
              <label for="SuccessMessage" class=" w-full font-light">Message</label>
            </div>
          </vs-row>

          <vs-row class="input-space mt-2" v-if="redirectData.success.enableRedirect">
            <div class="input-grp mb-6">
              <span class="text-danger text-sm" v-if="errors.has('SuccessButtonText')">{{ errors.first("SuccessButtonText") }}</span>
              <vs-input class="w-full" v-validate="'required|min:3|max:70'" size="large" v-model="redirectData.success.btntext" name="SuccessButtonText" id="SuccessButtonText" />
              <label for="SuccessButtonText" class=" w-full font-light">Action button text</label>
            </div>
            <div class="input-grp">
              <span class="text-danger text-sm" v-if="errors.has('SuccessLink')">{{ errors.first("SuccessLink") }}</span>
              <vs-input class="w-full" v-validate="'required|min:3|url'" size="large" v-model="redirectData.success.link" name="SuccessLink" id="SuccessLink" />
              <label for="SuccessLink" class=" w-full font-light">URL to redirect to</label>
            </div>
          </vs-row>
        </vs-row>
        <div class="mt-8 w-1/2" v-if="redirectData.success.enableRedirect">
          <p>Preview:</p>

          <div class="mt-4 md:mt-6 md:py-2 text-center sm:text-justify rounded-3xl template-preview">
            <div class="flex justify-center">
              <h3 class="font-medium text-lg pt-6 text-primary">{{ redirectData.success.heading || "Redirect heading" }}</h3>
            </div>
            <p class="text-center pt-6">
              {{ redirectData.success.message || "Redirect text. Lorem ipsum dolor sit amet, consectetur adipiscing elit" }}
            </p>
            <div class="ds-wrapper mt-10 text-center">
              <vs-button size="large" class="w-3/5">{{ redirectData.success.btntext || "Redirect button text" }}</vs-button>
              <p class="mt-6 mb-4 text-body-dark">Redirecting you in 15 seconds ...</p>
            </div>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
  import { Validator } from "vee-validate";

  const dictionary = {
    en: {
      attributes: {
        SuccessHeading: "heading",
        SuccessButtonText: "button text",
        SuccessMessage: "message",
        SuccessLink: "redirection url",
      },
    },
  };

  Validator.localize(dictionary);

  export default {
    name: "Redirection",

    props: ["editFlag", "isSubmitted", "paymentDataDefault", "paymentOptionData", "redirectData"],

    watch: {
      isFormDirty(val) {
        if (val) {
          this.$emit("checkDirty", this.isFormDirty);
        }
      },
      async isSubmitted() {
        await this.startValidating();
      },
    },

    methods: {
      auto_grow(elem) {
        var element = document.getElementById(elem);
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
        this.$refs.senderCollapse.maxHeight = "none !important";
      },

      async startValidating() {
        const valid = await this.$validator.validate();

        if (!valid) {
        this.$refs.senderCollapse.maxHeight = "none !important";
        }
      },
    }
  }
</script>