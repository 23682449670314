<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item open ref="permissionCollapse">
        <div slot="header">
          <h3 class="text-left card-title font-normal">Authorised users</h3>
        </div>
        <p class="mb-2">
          Set user permissions specific to this template (and requests created using this template)
        </p>

        <div class="flex mt-4">
          <label class="w-full text-base mr-5">All users have access to this template/HPP and it's payments</label>
          <vs-switch class="mr-5" v-model="enableAll" name="authorisedUser" @input="handleUsers()" />
        </div>

        <vs-row class="user-permission" v-if="!enableAll">
          <vs-table :data="merchantUsers" class="assign-users-table stripes w-1/2">
            <template slot="thead">
              <vs-th class="text-base text-left">User name</vs-th>
              <vs-th class="text-base">Role</vs-th>
              <vs-th class="text-base">Authorised to view/use?</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(user, indextr) in data">
                <vs-td :data="user.fullName" class="text-left">
                  {{ user.fullName ? user.fullName : user.companyName }}
                </vs-td>
                <vs-td :data="user.userType">
                  {{ getUserType(user) }}
                </vs-td>
                <vs-td>
                  <vs-checkbox v-model="user.isAuthorisedToUse" :vs-value="true" name="userPermission" @change="checkPermission()" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-row>
        <span class="text-danger text-sm mt-2" v-show="errors.has('userPermission')">{{ errors.first("userPermission") }}</span>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>
<script>
export default {
  props: ["authoriseAll", "isSubmitted", "merchantUsers", "templateConfigType"],

  data() {
    return {
      enableAll: true,
    }
  },

  methods: {
    getUserType(user) {
      let userType = "";

      switch (user.userType) {
        case "admin":
          userType = "Super Admin";
          break;

        case "staffAdmin":
          userType = "Admin";
          break;

        case "staff":
          userType = "User";
          break;
      }

      return userType;
    },

    handleUsers() {
      this.$emit("toggleChange", this.enableAll);
      this.checkPermission();
    },

    async checkPermission() {
      if (!this.enableAll) {
        const users = this.merchantUsers.filter((item) => item.isAuthorisedToUse);

        if (!users || !users.length) {
          this.errors.add({
            field: "userPermission",
            msg: "Please select at least one user"
          });
        } else if (this.errors.has("userPermission")) {
          this.errors.remove("userPermission");
        }
      } else if (this.errors.has("userPermission")) {
        this.errors.remove("userPermission");
      }
    }
  },

  mounted() {
    this.enableAll = this.authoriseAll;
  },

  watch: {
    async isSubmitted() {
      this.checkPermission();
    }
  }
}
</script>

