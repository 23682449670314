<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item :open="templateConfigType == 'direct'" ref="notificationCollapse" :not-arrow="isDirect" :class="isDirect ? 'non-collapsible' : ''">
        <div slot="header">
          <h3 class="text-left card-title">Notifications</h3>
        </div>
        <p class="mb-8">Configure notifications to be sent when payment requests are created and payments are processed using this template</p>
        <div class="mt-4 mb-8 grid grid-cols-2 gap-16">
          <div class="w-full">
            <h4>Email</h4>
            <hr class="my-2" />
            <p class="text-sm mb-8">Email notifications sent to you when payment requests are created and payments are processed using this template</p>
            <div>
              <vs-checkbox v-model="notification.options" vs-value="REQUEST_SENDER" name="options" @change="changeOption" v-validate="'required'">Request sender</vs-checkbox>
            </div>
            <div class="my-4">
              <vs-checkbox v-model="notification.options" vs-value="OTHER" name="options" @change="changeOption" v-validate="'required'">Other</vs-checkbox>
            </div>

            <span class="text-danger text-sm" v-show="errors.has('options')">Please select at least one option</span>

            <div v-if="notification.options.includes('OTHER')" class="mt-4 mb-6 w-2/3">
              <label for="emailAddress" class="block w-full text-xm font-normal mt-2 pb-3 text-base">Email address <span class="mid-blue">*</span></label>
              <div v-for="(newEmail, index) in multipleEmailAddress" :key="index" class="flex flex-row justify-between mb-4 md:mb-6">
                <div class="mr-8 w-full">
                  <vs-input size="large"
                    v-model="newEmail.email"
                    data-vv-validate-on="blur"
                    data-vv-as="email address"
                    :name="'newEmail'+index"
                    :id="'multipleEmailAddress'+index"
                    class="w-full"
                    v-validate="notification.options.includes('OTHER') ? 'required|email' : ''"
                    @keypress="changesEmail"
                    @change="changesEmail"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('emailAddress')">{{ errors.first("emailAddress") }}</span>
                  <span class="text-danger text-sm" v-show="errors.has('newEmail'+index)">{{ errors.first('newEmail'+index) }}</span>
                </div>
                <feather-icon v-if="multipleEmailAddress.length > 1" icon="Trash2Icon" svgClasses="h-6 w-6 hover:text-danger cursor-pointer" @click="removeEmail(index)" />
              </div>
              <span @click="addEmail" v-if="multipleEmailAddress.length <= 4" class="text-link flex flex-row pt-2 mb-6 md:mb-8"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
            </div>
          </div>
          <div>
            <h4>HPP Webhook</h4>
            <hr class="my-2" />
            <p class="text-sm mb-8">Webhook (API) notifications sent to an external system you when payment requests are created and payments are processed using a HPP generated from this template</p>

            <div>
              <label for="webhookUrl" class="w-full text-xm font-normal">Webhook URL</label>
              <vs-input
                size="large"
                v-model="webhookUrl"
                data-vv-validate-on="blur"
                data-vv-as="webhook url"
                v-validate="{ url: { require_protocol: true } }"
                name="webhookUrl"
                id="webhookUrl"
                class="w-8/12"
                @blur="handleWebhookUrl"
              />
              <span class="text-danger text-sm" v-show="errors.has('webhookUrl')">{{ errors.first("webhookUrl") }}</span>
            </div>
            <div class="mt-4">
              <label for="webhookToken" class="w-full text-xm font-normal">Webhook token</label>
              <vs-input
                size="large"
                v-model="webhookToken"
                data-vv-validate-on="blur"
                data-vv-as="webhookToken"
                name="webhookToken"
                id="webhookToken"
                class="w-8/12"
                @blur="handleWebhookToken"
              />
            </div>
          </div>
        </div>

        <vs-table :data="prNotification" class="mb-10">
          <template slot="thead">
            <vs-th class="force-th-width v-baseline-aligned">Status</vs-th>
            <vs-th class="v-baseline-aligned">Description</vs-th>
            <vs-th class="v-baseline-aligned" width="5%">Send</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td v-if="tr.display" :data="tr.status">{{ tr.status }}</vs-td>
              <vs-td v-if="tr.display" :data="tr.description">{{ tr.description }}</vs-td>
              <vs-td v-if="tr.display" :data="tr.forEmail">
                <div class="flex justify-center">
                  <vs-checkbox v-model="tr.forEmail" class="ml-0"></vs-checkbox>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>
<script>
import { EditIcon, PlusIcon } from "vue-feather-icons";

export default {
  components:{ PlusIcon, EditIcon },
  props: [
    "notification",
    "prNotificationsCheckbox",
    "isSubmitted",
    "paymentOptionData",
    "templateConfigType",
    "webhookTokenData",
    "webhookUrlData"
  ],
  data() {
    return {
      active_el: true,
      notificationDisabled: false,
      trCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      prCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      prNotification: [],
      defaultNotification: {
        prNotification: [],
      },
      multipleEmailAddress: [],
      webhookUrl: "",
      webhookToken: "",
      isDirect: false,
      options: []
    };
  },
  mounted() {
    this.isDirect = (this.templateConfigType == "direct");
    this.prNotification = this.notification.prNotification;

    // store for later use
    this.defaultNotification.prNotification = this.prNotification;

    this.prCheckbox = this.prNotificationsCheckbox;
    this.active_el = this.notification.requestSenderEmail;
    this.webhookUrl = this.webhookUrlData;
    this.webhookToken = this.webhookTokenData;

    if (this.notification && this.notification.multipleEmailAddress && this.notification.multipleEmailAddress.length > 0) {
      this.multipleEmailAddress= this.notification.multipleEmailAddress.map(item=> {return {email: item}});
    } else {
      this.multipleEmailAddress = [{ email: "" }];
    }

    this.checkNotification();
  },
  watch: {
    notification: async function (val) {
      if (val.prNotification) {
        this.prNotification = val.prNotification;
        this.checkNotification();
      }
    },
    prNotificationsCheckbox: async function (val) {
      if (val) {
        this.prCheckbox = val;
      }
    },
    async isSubmitted(value) {
      this.startValidating();
    },
    "paymentOptionData.requestType"(val) {
      if (val) {
        this.checkNotification();
      }
    },
    "paymentOptionData.requestOptions"(val) {
      if (val) {
        this.checkNotification();
      }
    },
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  },
  methods: {
    removeEmail(index) {
      this.multipleEmailAddress.splice(index, 1);
      this.notification.multipleEmailAddress = this.multipleEmailAddress;
      const key = this.errors.items.findIndex(object => { return object.field === 'newEmail'+index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },
    changesEmail(val) {
      this.notification.multipleEmailAddress = this.multipleEmailAddress;
    },
    addEmail() {
      this.multipleEmailAddress.push({ email: "" });
    },
    changeOption() {
      this.$refs.notificationCollapse.maxHeight = "none !important";
      this.$emit("changeNotification", this.notification.options);
    },
    checkNotification() {
      let defaultPrNotification = this.$lodashHelper.checkObjectKey(this.defaultNotification, "prNotification", []);
      defaultPrNotification = defaultPrNotification.filter((item) => !["Expired", "Extended"].includes(item.status));

      if (this.paymentOptionData.requestType == "one-off") {
        if (this.templateConfigType == "pay-later") {
          this.prNotification = defaultPrNotification;
        } else {
          const filterNotification = (this.templateConfigType == "pay-now") ? ["Active", "Declined", "Info required"] : ["Info required", "Pending"];
          this.prNotification = defaultPrNotification.filter((el) => !filterNotification.includes(el.status));
          this.prNotification = this.prNotification.map(item => {
            if (item.status == "Complete") {
              item.description = (this.templateConfigType == "pay-now") ? "Request has been paid successfully" : "Activated finance option has been repaid";
            }
            return item;
          });
        }
      } else {
        // for recurring
        this.prNotification = defaultPrNotification.filter((el) => el.status != "Info required");
      }
    },
    async startValidating() {
      await this.$validator.validate();
    },

    handleWebhookUrl() {
      this.$emit("updateWebhookUrl", this.webhookUrl);
    },

    handleWebhookToken() {
      this.$emit("updateWebhookToken", this.webhookToken);
    }
  },
  created() {
    this.requestOptions = this.$store.state.AppActiveUser.requestOptions;
  }
};
</script>

