<template>
  <div>
    <vs-popup :title="title" class="custom-popup delete-pop-up" :active.sync="isPopupActive">
      <div class="mb-4 md:mb-8">
        <p>{{ content }}</p>
      </div>
      <div class="actions mt-5 text-right w-full">
        <vs-button class="btn btn-delete mr-8 large" @click="handleClick('leave')" v-round>Leave</vs-button>
        <a class="mid-blue" @click="handleClick('close')">Cancel</a>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: "LeavePopup",
  props: {
    popupActive: {
      required: true,
      type: Boolean
    },
    title: {
      type: String,
      default: "Leave without saving?"
    },
    content: {
      type: String,
      default: "Changes you made will be lost."
    }
  },
  computed: {
    isPopupActive: {
      // getter
      get: function () {
        return this.popupActive
      },
      // setter
      set: function (newValue) {

        this.handleClick('close');
       }
    }
  },
  methods: {
    handleClick(value) {
      this.$emit("handleClick", value);
      return;
    }
  }
}
</script>