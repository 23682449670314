<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="remittanceAdviceCollapse">
        <div slot="header">
          <h3 class="text-left card-title">Remittance advice</h3>
        </div>

        <p class="mb-8" v-if="templateConfigType === 'pay-later'">We'll send remittance advice of funds disbursed to the following email/s when payments using this template are processed.</p>
        <p class="mb-8" v-else>We'll send remittance advice of funds disbursed to the following email/s, when an agreement is activated using a payment request generated from this template.</p>

        <vs-row class="notific-cards">
          <vs-col class="spacing-radio w-auto">
            <div class="radio-card remittance-radio space-below" @click="activateRemittanceAdvice(true)" :class="{ active: active_el }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Default</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="defaultRemittanceAdvice" vs-value="1" vs-name="remittanceAdvice"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Use the email address configured on the bank account selected for disbursement</span><br/>
                  <span class="radio-info">Email: {{ defaultEmail }}</span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <vs-col class="w-auto">
            <div class="radio-card remittance-radio" @click="activateRemittanceAdvice(false)" :class="{ active: !active_el }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Other</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="defaultRemittanceAdvice" vs-value="0" vs-name="remittanceAdvice"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Override the bank account default for all requests created using this template</span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <vs-row v-if="!defaultRemittanceAdvice">
          <vs-col vs-xs="12" vs-sm="8" vs-lg="4">
            <label for="emailAddress" class="block w-full text-xm font-normal mt-8 pb-3 text-base">Email address <span class="mid-blue">*</span></label>
            <div v-for="(newEmail, index) in multipleEmailAddress" :key="index" class="flex flex-row justify-between mb-4 md:mb-6">
              <div class="mr-8 w-full">
                <vs-input size="large" v-model="newEmail.email" data-vv-validate-on="blur" data-vv-as="email address" :name="'newEmail'+index"
                  :id="'multipleEmailAddress'+index" class="w-full" v-validate="!defaultRemittanceAdvice ? 'required|email' : ''" @keypress="changesEmail"
                  @change="changesEmail" />
                <span class="text-danger text-sm" v-show="errors.has('emailAddress')">{{ errors.first("emailAddress") }}</span>
                <span class="text-danger text-sm" v-show="errors.has('newEmail'+index)">{{ errors.first("newEmail" + index) }}</span>
              </div>
              <feather-icon v-if="multipleEmailAddress.length > 1" icon="Trash2Icon" @click="removeEmail(index)" />
            </div>
            <span @click="addEmail" v-if="multipleEmailAddress.length" class="text-link flex flex-row pt-2 mb-6 md:mb-8"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
          </vs-col>
        </vs-row>
        <vs-row v-if="!defaultRemittanceAdvice">
          <vs-col vs-w="8" class="flex mb-4">
            <vs-switch v-model="remittanceAdvice.allowOverride" @change="changeToggles" />
            <p class="ml-8">Allow request sender to <b>edit</b> these details, per request</p>
          </vs-col>
          <vs-col vs-w="8" class="flex mb-4">
            <vs-switch v-model="remittanceAdvice.allowAdd" :disabled="remittanceAdvice.allowOverride" />
            <p class="ml-8">Allow request sender to <b>add</b> email addresses to receive remittance advice, per request</p>
          </vs-col>
        </vs-row>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>
<script>
import { EditIcon, PlusIcon } from "vue-feather-icons";
import _ from "lodash";

export default {
  components: { EditIcon, PlusIcon },
  props: ["defaultEmail", "isSubmitted", "remittanceAdvice", "templateConfigType"],
  data() {
    return {
      active_el: true,
      defaultRemittanceAdvice: true,
      notificationDisabled: false,
      multipleEmailAddress: [],
    };
  },
  mounted() {
    const defaultSettings = this.$lodashHelper.checkObjectKey(this.remittanceAdvice, "bankAccountDefault", true);
    this.active_el = defaultSettings;
    this.defaultRemittanceAdvice = defaultSettings;

    if (this.remittanceAdvice && this.remittanceAdvice.emailAddress && this.remittanceAdvice.emailAddress.length > 0) {
      this.multipleEmailAddress = this.remittanceAdvice.emailAddress.map(item => { return { email: item } });
    } else {
      this.multipleEmailAddress = [{ email: "" }];
    }
  },
  watch: {
    async isSubmitted() {
      this.startValidating();
    },
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
    "remittanceAdvice.bankAccountDefault"(val) {
      if (!val && !this.remittanceAdvice.emailAddress.length) {
        this.active_el = false;
        this.defaultRemittanceAdvice = false;
        this.multipleEmailAddress = [{ email: "" }];
      }
    },
    "errors.items"(val) {
      if (this.isSubmitted && this.errors.items.length > 0) {
        this.$refs.remittanceAdviceCollapse.maxHeight = "none !important";
      }
    }
  },
  methods: {
    removeEmail(index) {
      this.multipleEmailAddress.splice(index, 1);
      this.remittanceAdvice.emailAddress = this.multipleEmailAddress;
      const key = this.errors.items.findIndex(object => { return object.field === "newEmail" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },
    changesEmail() {
      this.remittanceAdvice.emailAddress = this.multipleEmailAddress;
    },
    addEmail() {
      this.multipleEmailAddress.push({ email: "" });
    },
    activateRemittanceAdvice(value) {
      this.active_el = value;
      this.defaultRemittanceAdvice = value;
      this.remittanceAdvice.bankAccountDefault = value;
      this.remittanceAdvice.allowAdd = false;
      this.remittanceAdvice.allowOverride = false;
      this.multipleEmailAddress = [];

      if (!value) {
        this.multipleEmailAddress = [{ email: "" }];
        this.remittanceAdvice.emailAddress = [];
      }

      this.$refs.remittanceAdviceCollapse.maxHeight = "none !important";
    },
    async startValidating() {
      await this.$validator.validate();
    },
    changeToggles() {
      if (this.remittanceAdvice.allowOverride) {
        this.remittanceAdvice.allowAdd = true;
      }
    }
  }
};
</script>

